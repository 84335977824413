import { Button, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import BackButton from "../BackButton";
import InputRadioFieldGrid from "../common/InputRadioFieldGrid";
import InputSelectFieldGrid from "../common/InputSelectFieldGrid";
import InputTextFieldGrid from "../common/InputTextFieldGrid";
import SteepsBox from "../common/SteepsBox";
import { useDispatch, useSelector } from "react-redux";
import { getSalePointTypesService } from "../../services/client/salePointService";
import { setSalePointTypes } from "../../redux/slices/client/salePointSlice";
import { errorAlert } from "../../helpers/alerts";
import {
  getBagService,
  getEarningsPaymentService,
  getEarningsSchemeService,
  getTypesService,
} from "../../services/client/bagService";
import {
  setCurrentBag,
  setEarningsPayment,
  setEarningsScheme,
  setTypes,
} from "../../redux/slices/client/bagSlice";
import CheckboxListFieldGrid from "components/common/CheckboxListFieldGrid";
import { getGroupsService } from "services/client/groupService";
import { setGroups } from "redux/slices/client/groupSlice";
import CheckboxFieldGrid from "components/common/CheckboxFieldGrid";

const CommerceForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const dispatch = useDispatch();
  const { mdd, ...session } = useSelector((store) => store.session);
  const { control, handleSubmit, setValue } = useForm({ defaultValues });
  const { earningsPayment, earningsScheme, types } = useSelector(
    (store) => store.bags
  );
  const { commerceTypes } = useSelector((store) => store.commerces);
  const { salePointTypes } = useSelector((store) => store.salePoint);
  const { groups } = useSelector((store) => store.group);
  const watch = useWatch({ control });
  const { type_commerce, type_bag, conciliation } = watch;
  
  useEffect(() => {
    getEarningsPaymentService()
      .then((response) => dispatch(setEarningsPayment(response)))
      .catch((e) => errorAlert(e));
    getEarningsSchemeService()
      .then((response) => dispatch(setEarningsScheme(response)))
      .catch((e) => errorAlert(e));
    getTypesService()
      .then((response) => dispatch(setTypes(response)))
      .catch((e) => errorAlert(e));
    getSalePointTypesService()
      .then((response) => dispatch(setSalePointTypes(response)))
      .catch((e) => errorAlert(e));
    getGroupsService()
      .then((response) => dispatch(setGroups(response)))
      .catch((e) => errorAlert(e));
    // eslint-disable-next-line
  }, []);

  const steps = [
    {
      label: "Datos del comercio",
      content: [
        {
          type: "input",
          xs: 3,
          name: "commerce_name",
          required: "Debes agregar el nombre del comercio",
          label: "Nombre del comercio",
          onTextChange: (value) => {
            setValue("name_bag", `Bolsa ${value}`);
            setValue("name_salepoint", `PV ${value}`);
          },
        },
        {
          type: "input",
          xs: 3,
          name: "name",
          label: "Nombre del responsable",
          required: "Debes agregar el nombre del responsable",
        },
        {
          type: "input",
          xs: 3,
          name: "last_name",
          label: "Apellido Paterno",
        },
        {
          type: "input",
          xs: 3,
          name: "second_last_name",
          label: "Apellido Materno",
        },
        {
          type: "input",
          xs: 6,
          name: "street_number",
          label: "Calle y número",
        },
        {
          type: "input",
          xs: 6,
          name: "municipality",
          label: "Delegación / Municipio",
        },
        {
          type: "input",
          xs: 3,
          name: "cp",
          label: "C.P.",
          inputType: "number",
        },
        {
          type: "input",
          xs: 3,
          name: "state_address",
          label: "Estado",
        },
        {
          type: "input",
          xs: 3,
          name: "phone",
          label: "Telefono",
          rules: {
            required: {
              value: true,
              message: "Debes agregar el número celular",
            },
            maxLength: {
              value: 10,
              message: "El número celular es de máximo 10 dígitos",
            },
            pattern: {
              value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              message: "Número celular inválido",
            },
          },
          inputType: "tel",
          inputProps: {
            type: "tel",
            maxLength: 10,
          },
        },
        {
          type: "input",
          xs: 3,
          name: "mobile",
          label: "Celular",
          rules: {
            required: {
              value: true,
              message: "Debes agregar el número celular",
            },
            maxLength: {
              value: 10,
              message: "El número celular es de máximo 10 dígitos",
            },
            pattern: {
              value: /^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
              message: "Número celular inválido",
            },
          },
          inputType: "tel",
          inputProps: {
            type: "tel",
            maxLength: 10,
          },
        },
      ],
    },
    {
      label: "Ajustes Administrador",
      content: [
        {
          type: "select",
          xs: 3,
          name: "type_commerce",
          required: "Debes agregar tipo de comercio",
          label: "Tipo de comercio",
          data: commerceTypes,
        },
        {
          type: "input",
          xs: 3,
          name: "social_reason",
          label: "Razón social",
        },
        {
          type: "input",
          xs: 3,
          name: "rfc",
          label: "RFC",
        },
        {
          type: "input",
          xs: 3,
          name: "user_commerce",
          required: "Debes agregar email",
          label: "Correo",
          disabled: defaultValues.user_commerce,
          pattern: {
            value:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "El correo electrónico no es valido",
          },
        },
        {
          type: "input",
          xs: 3,
          name: "sessionTime",
          label: "Tiempo de sesión (minutos)",
          inputType: "number",
        },
        {
          type: "select",
          xs: 3,
          name: "group",
          label: "Grupo",
          showClean: true,
          data: groups,
        },
        type_commerce === "H2H" && {
          type: "input",
          xs: 3,
          name: "ip",
          label: "IP",
          placeholder: "0.0.0.0",
          pattern: {
            value:
              /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            message: "La IP no es valida",
          },
        },
        type_commerce === "H2H" && {
          type: "checkbox",
          xs: 3,
          name: "conciliation",
          label: "Habilitar conciliación",
        },
        !mdd &&
        type_commerce === "DISTRIBUIDOR" && {
          type: "input",
          xs: 3,
          name: "mdd",
          label: "Marca blanca (URL)",
        },
        conciliation && {
          type: "input",
          xs: 3,
          name: "conciliation_path",
          label: "Ruta de conciliación",
        },

        // { type: "grid", xs: 6 },
      ].filter((item) => item),
    },
    {
      label: "Ajustes de Bolsa",
      content: [
        {
          type: "input",
          xs: 4,
          name: "name_bag",
          disabled: true,
          required: "Debes agregar la etiqueta del comercio",
          label: "Nombre de la bolsa",
        },
        {
          type: "input",
          xs: 4,
          name: "min_amount",
          label: "Monto minimo",
          inputType: "number",
        },
        {
          type: "select",
          xs: 4,
          name: "type_bag",
          required: "Debes seleccionar por lo menos un tipo de bolsa",
          label: "Tipos de bolsa",
          data: types,
          multiple: true,
        },
        type_bag?.includes('TAE') && { type: "grid", xs: 6, name: "Esquema ganancia / campo comisión: " },
        type_bag?.includes('TAE') && { type: "grid", xs: 6, name: "Abono Ganancia: " },
        type_bag?.includes('TAE') && {
          type: "radio",
          xs: 6,
          name: "earnings_scheme",
          required: "Debes seleccionar uun esquema de ganancia",
          data: earningsScheme.map((item) => ({
            ...item,
            // disabled:
            //   currentBag?.earnings_scheme === "COMISION" &&
            //   item.id === "DESCUENTO",
          })),
        },
        type_bag?.includes('TAE') && {
          type: "radio",
          xs: 6,
          name: "earnings",
          required: "Debes seleccionar un esquema de ganancia",
          data: earningsPayment,
        },
      ].filter(Boolean),
    },
    ["MENUDEO", "DISTRIBUIDOR"].includes(type_commerce) && {
      label: "Ajustes de Punto de Venta",
      content: [
        {
          type: "input",
          xs: 6,
          name: "name_salepoint",
          disabled: true,
          required: "Debes agregar el nombre del punto de venta",
          label: "Nombre del punto de venta",
        },
        {
          type: "select",
          xs: 6,
          name: "type_salepoint",
          required: "Debes agregar tipo de punto de venta",
          label: "Tipo de punto de venta",
          data: salePointTypes,
          multiple: true,
        },
        {
          type: "input",
          xs: 12,
          name: "description_salepoint",
          label: "Descripcion",
        },
        {
          type: "input",
          xs: 4,
          name: "limit_amount_day",
          label: "Límite de saldo por día",
          inputType: "number",
        },
        {
          type: "input",
          xs: 4,
          name: "open_hour",
          label: "Hora de apetura",
          inputType: "time",
        },
        {
          type: "input",
          xs: 4,
          name: "close_hour",
          label: "Hora de cierre",
          inputType: "time",
        },
        {
          type: "checkboxList",
          // xs: 6,
          name: "days",
          label: "Dias",
          required: "Debes seleccionar por lo menos un dia",
          list: [
            { label: "Lunes", name: "monday" },
            { label: "Martes", name: "tuesday" },
            { label: "Miercoles", name: "wednesday" },
            { label: "Jueves", name: "thursday" },
            { label: "Viernes", name: "friday" },
            { label: "Sabado", name: "saturday" },
            { label: "Domingo", name: "sunday" },
          ],
        },
      ],
    },
  ].filter((item) => item);

  return (
    <>
      <BackButton name={title} click={goBack} />
      <Grid container direction="row" maxWidth={"100%"} justifyContent="center">
        <SteepsBox
          steps={steps}
          formatter={(content) =>
            content.map((item, i) =>
              item.type === "input" ? (
                <InputTextFieldGrid
                  key={i}
                  xs={item.xs}
                  name={item.name}
                  control={control}
                  required={item.required}
                  pattern={item.pattern}
                  rules={item.rules}
                  label={item.label}
                  disabled={item.disabled}
                  inputType={item.inputType}
                  step={item.step}
                  placeholder={item.placeholder}
                  onTextChange={item.onTextChange}
                />
              ) : item.type === "radio" ? (
                <InputRadioFieldGrid
                  key={i}
                  xs={item.xs}
                  name={item.name}
                  control={control}
                  required={item.required}
                  label={item.label}
                  data={item.data}
                />
              ) : item.type === "select" ? (
                <InputSelectFieldGrid
                  key={i}
                  xs={item.xs}
                  name={item.name}
                  control={control}
                  required={item.required}
                  label={item.label}
                  data={item.data}
                  disabled={item.disabled}
                  multiple={item.multiple}
                  showClean={item.showClean}
                />
              ) : item.type === "checkboxList" ? (
                <CheckboxListFieldGrid
                  key={i}
                  xs={item.xs}
                  name={item.name}
                  control={control}
                  required={item.required}
                  label={item.label}
                  list={item.list}
                />
              ) : item.type === "checkbox" ? (
                <CheckboxFieldGrid
                  key={i}
                  xs={item.xs}
                  name={item.name}
                  control={control}
                  required={item.required}
                  label={item.label}
                  // list={item.list}
                />
              ) : (
                <Grid item xs={item.xs} textAlign={"center"} key={i}>
                  {item.name}
                </Grid>
              )
            )
          }
        />
        <Grid container justifyContent="center" mt={"40px"}>
          <Button
            id="save"
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            // disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default CommerceForm;
