import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { useForm, useWatch } from "react-hook-form";
import BackButton from "../BackButton";
import InputTextFieldGrid from "../common/InputTextFieldGrid";
import InputImageFieldGrid from "../common/InputImageFieldGrid";
import CheckboxFieldGrid from "components/common/CheckboxFieldGrid";
import InputChipFieldGrid from "components/common/InputChipFieldGrid";

const ProductForm = ({ title, goBack, defaultValues, submit, validate, type }) => {
  const { control, handleSubmit, setValue } = useForm({ defaultValues });
  const watch = useWatch({ control });

  useEffect(() => {
    if (!watch.fixedAmount) {
      setValue('amounts', [])
    }
    // eslint-disable-next-line
  }, [watch.fixedAmount])
  
  useEffect(() => {
    if (watch.referenceVariable) {
      setValue('referenceLength', '')
    }
    // eslint-disable-next-line
  }, [watch.referenceVariable])

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          m={"40px"}
          maxWidth={"70%"}
          justifyContent="center"
          spacing={3}
        >
          <InputTextFieldGrid
            xs={9}
            name={"name"}
            control={control}
            required={"Debes agregar el nombre"}
            label={"Nombre"}
          />
          <InputTextFieldGrid
            xs={3}
            name={"code"}
            control={control}
            required={"Debes agregar el código"}
            label={"Código"}
          />
          <InputImageFieldGrid
            xs={type === 'pds' ? 6 : 12}
            name={"logo"}
            control={control}
            required={"Debes agregar un logo"}
            label={"Logo"}
          />
          {
            type === 'pds' ? (
              <>
                <InputImageFieldGrid
                  xs={6}
                  name={"imageReceipt"}
                  control={control}
                  label={"Recibo"}
                />
                <InputTextFieldGrid
                  xs={12}
                  name={"instructions"}
                  control={control}
                  multiline={true}
                  label={"Instrucciones"}
                />
                <CheckboxFieldGrid
                  xs={6}
                  name={'referenceVariable'}
                  control={control}
                  label={'Referencia variable'}
                />
                <CheckboxFieldGrid
                  xs={6}
                  name={'referenceAlphaNumeric'}
                  control={control}
                  label={'Referencia alfanumérica'}
                />
                <InputTextFieldGrid
                  xs={6}
                  name={"referenceLength"}
                  control={control}
                  label={"Longitud de referencia"}
                  inputType={"number"}
                  disabled={watch.referenceVariable}
                />
                <InputTextFieldGrid
                  xs={6}
                  name={"referenceName"}
                  control={control}
                  label={"Nombre de referencia"}
                />
                <InputTextFieldGrid
                  xs={12}
                  name={"applicationTime"}
                  control={control}
                  label={"Tiempo de aplicación"}
                />
                <CheckboxFieldGrid
                  xs={6}
                  name={'partialPayments'}
                  control={control}
                  label={'Pagos parciales'}
                />
                <CheckboxFieldGrid
                  xs={6}
                  name={'expiredPayments'}
                  control={control}
                  label={'Pagos vencidos'}
                />
                <InputTextFieldGrid
                  xs={6}
                  name={"minPayment"}
                  control={control}
                  label={"Pago minimo"}
                  inputType={"number"}
                />
                <InputTextFieldGrid
                  xs={6}
                  name={"maxPayment"}
                  control={control}
                  label={"Pago máximo"}
                  inputType={"number"}
                />
                 <InputTextFieldGrid
                  xs={12}
                  name={"customerService"}
                  control={control}
                  multiline={true}
                  label={"Atención a clientes"}
                />
                <CheckboxFieldGrid
                  xs={4}
                  name={'fixedComission'}
                  control={control}
                  label={'Comisión fija'}
                />
                <CheckboxFieldGrid
                  xs={4}
                  name={'finalClientPayComission'}
                  control={control}
                  label={'Cliente final paga comisión'}
                />
                <CheckboxFieldGrid
                  xs={4}
                  name={'discountAmount'}
                  control={control}
                  label={'Cargo se descuenta de importe'}
                />
              </>
            ) : (
              <>
                <CheckboxFieldGrid
                  xs={6}
                  name={'fixedAmount'}
                  control={control}
                  label={'Montos fijos'}

                />
                {
                  watch.fixedAmount && (
                    <InputChipFieldGrid
                      xs={6}
                      name={"amounts"}
                      control={control}
                      label={"Montos"}
                    />
                  )
                }
              </>
            )
          }
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            // disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ProductForm;
