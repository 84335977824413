import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { BackButton, CustomTab } from '../../../components';
import { Grid } from '@mui/material';

const ProductTabScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          {
            ![
              '/admin/productos/tae', 
              '/admin/productos/pds', 
              '/admin/productos/pin'
            ].includes(location.pathname) && <BackButton click={() => navigate(-1)} />
          }
          {/* <BackButton
            name={"Volver"}
            click={() => navigate(-1)}
          /> */}
        </div>
        <div className="container-title">
        </div>
        <div>
        </div>
      </Grid>
      <CustomTab
        panels={[
          {
            name: "Recargas Celular",
            route: '/admin/productos/tae',
            onChange: () => navigate('tae'),
            content: <Outlet />
          },
          {
            name: "Pago de Servicios",
            route: '/admin/productos/pds',
            onChange: () => navigate('pds'),
            content: <Outlet />
          },
          {
            name: "Tarjetas de Regalo",
            route: '/admin/productos/pin',
            onChange: () => navigate('pin'),
            content: <Outlet />
          }
        ].filter(Boolean)}
      />
    </div>
  );
}

export default ProductTabScreen