import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid } from '@mui/material'
import React from 'react'
import { Controller, useForm } from 'react-hook-form';

const DialogModal = ({
  title,
  open,
  body,
  successText = 'Aceptar',
  onSuccess,
  closeText = 'Cancelar',
  onClose,
  additionalButton
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      fullWidth={true}
      sx={{ padding: 4 }}
    >
      <>
        <DialogTitle sx={{ textAlign: 'center' }}>{title}</DialogTitle>
        <DialogContent sx={{ textAlign: 'center', fontSize: 16 }} dividers>
          {body}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', gap: 4, padding: 2 }}>
          {onSuccess && <Button
            color='primary'
            id='columnAccept'
            onClick={onSuccess}
            variant="contained"
          >
            {successText}
          </Button>}
          {additionalButton}
          {onClose && <Button
            color='error'
            onClick={onClose}
            variant="contained"
          >
            {closeText}
          </Button>}
        </DialogActions>
      </>
    </Dialog>
  )
}

export default DialogModal