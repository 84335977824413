import { EditOutlined } from "@mui/icons-material";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CardBox } from "../../../../../components";
import { errorAlert } from "../../../../../helpers/alerts";
import { validatePermission } from "../../../../../helpers/authentication";
import { setCurrentPINProduct, setCurrentPINSegment, setPINProducts, setPINSegments } from "redux/slices/admin/providerSlice";
import { getCredPINProductsService, getCredPINSegmentsService } from "services/admin/providerService";

const CredPINScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    providerCredSelected,
    pinProducts,
    currentPINProduct
  } = useSelector((store) => store.providers);

  useEffect(() => {
    getCredPINProductsService(providerCredSelected.id)
      .then(list => {
        dispatch(setPINProducts(list));
      }).catch(error => errorAlert(error))
    // eslint-disable-next-line
  }, [providerCredSelected]);

  const handlerClickProduct = (product) => {
    if (product.id !== currentPINProduct?.id) {
      dispatch(setCurrentPINProduct(product));
    }
  };

  return (
    <CardBox
      values={pinProducts?.filter(({ state }) => state)}
      handleSelect={handlerClickProduct}
      buttons={[
        validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
        {
          icon: <EditOutlined />,
          key: "products",
          name: "Administrar",
          click: () => navigate("productos"),
        },
      ]}
      formatter={({ idProduct: item }) => ({
        ...item,
        image: `/v3/file/${item?.fd}/`,
      })}
    />
  );
};

export default CredPINScreen;
