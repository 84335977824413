import React, { useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { setTitle } from 'redux/slices/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { BackButton, CustomTab } from 'components';
import { Grid } from '@mui/material';

const ErrorCodesTabScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { current } = useSelector(store => store.providers)

  useEffect(() => {
    dispatch(setTitle('Homologación Errores Proveedor'));
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <Grid container direction="row" justifyContent="space-between" alignItems="center" >
        <div>
          <BackButton click={() => navigate([
            '/admin/homologacion/errorcodes/homologados',
            '/admin/homologacion/errorcodes/recibidos'
          ].includes(location.pathname) ? '..' : -1 )} />
        </div>
        <div className='container-title'>
          <div className='commerce'>Proveedor: {current.container_name} </div>
        </div>
        <div>
        </div>
      </Grid>
      <CustomTab
        panels={[
          {
            name: "Homologados",
            route: '/admin/homologacion/errorcodes/homologados',
            onChange: () => navigate('homologados'),
            content: <Outlet />
          },
          {
            name: "Recibidos",
            route: '/admin/homologacion/errorcodes/recibidos',
            onChange: () => navigate('recibidos'),
            content: <Outlet />
          },
        ].filter(Boolean)}
      />
    </div>
  );
}

export default ErrorCodesTabScreen