import { Grid, Skeleton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './MenuHeader.css';
import { cleanSession, setSession } from '../../redux/slices/sessionSlice';
import { currencyFormatter } from '../../helpers/util';
import { getBagsService, getSessionBagsService } from '../../services/client/bagService';
import { ChevronLeft, HideImageRounded, Visibility, VisibilityOff } from '@mui/icons-material';
import { showSidebar } from '../../redux/slices/uiSlice';
import Image from '../../assets/images';
import { useLocation, useNavigate } from 'react-router-dom';
import IconUser from './IconUser';
import theme from '../../config/theme';

const MenuHeader = ({ loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { hoster, nameUser, rol, email, bags, updateBalance } = useSelector(store => store.session);
  const [hosterName, setHosterName] = useState('');
  const [showBalance, setShowBalance] = useState(false)

  useEffect(() => {
    if (location.search === '?session_expired')
      return;
    if (hoster)
      getSessionBagsService().then(bags => {
        dispatch(setSession({
          bags: bags.map(bag => ({
            id: bag.id,
            type: bag.type,
            balance: bag.saldo,
          }))
        }))
        setHosterName(bags[0]?.hoster?.username)
      }).catch((e) => console.error(e))
    // eslint-disable-next-line
  }, [hoster, updateBalance])

  const closeSession = () => {
    dispatch(cleanSession())
    navigate('/')
  }

  return (
    <div className='menu-header'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div id={'showSidebar'} className='menu-sidebar-button' onClick={() => dispatch(showSidebar(false))}>
          <ChevronLeft className='sidebar-button-icon' />
        </div>
        <div className='container-header-logo'>
          <img alt='logo' className='header-logo' src={Image[theme].logo} />
        </div>
      </div>
      {
        loading ? (
          <Grid container direction={'row'} columnSpacing={2} alignItems={'center'}>
            <Grid item >
              <Skeleton variant="rectangular" width={210} height={25} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={15} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={15} style={{ marginBottom: 4 }} />
              <Skeleton variant="rectangular" width={210} height={25} />
            </Grid>
          </Grid>
        ) : (
          <Grid container paddingX={'1vw'} className='menu-header-wrapper'>
            <Grid item xs={12} md={3} justifyItems={'center'}>
              <IconUser name={hoster ? hosterName : nameUser} />
            </Grid>
            <Grid item container xs={12} md={9} flexDirection={'column'} alignItems={'center'} textAlign={'center'}>
              <Grid item direction={'row'} columnSpacing={2} flex={1}>
                <p className='menu-header-info-name'>{hoster ? hosterName : nameUser}</p>
                <p className='menu-header-info-name'>{email}</p>
                <p className='menu-header-info-user'>{rol?.name}</p>
              </Grid>
              {
                bags?.length > 0 && (
                  <Grid item container direction={'row'} columnSpacing={1} alignItems={'center'} >
                    <Grid item flex={1}>
                      {
                        bags?.map(bag => (
                          <p key={bag.id} className='menu-header-info-bag' style={{ flex: 1 }} >Saldo {bag.type}: {showBalance ? currencyFormatter.format(bag.balance) : '**********'}</p>
                        ))
                      }
                    </Grid>
                    <Grid item justifyContent={'center'} alignItems={'center'} onClick={() => setShowBalance(!showBalance)}>
                      {showBalance ? <VisibilityOff /> : <Visibility />}
                    </Grid>
                  </Grid>
                )
              }
              <Grid item flex={1} >
                <p className='menu-header-info-close-session' onClick={closeSession}>Cerrar sesión</p>
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </div>
  )
}

export default MenuHeader;