import React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BackButton, CustomTab } from 'components';
import { Grid } from '@mui/material';

const CredsProductTabScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { providerSelected, providerCredSelected } = useSelector(store => store.providers)
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate([
            '/admin/proveedores/creds/productos/tae',
            '/admin/proveedores/creds/productos/pds',
            '/admin/proveedores/creds/productos/pin'
          ].includes(location.pathname) ? '..' : -1)} />
        </div>
        <div className="container-title">
          <div className="commerce">{providerCredSelected.conciliacion_service}</div>
          <div className="bag">{providerCredSelected.name_connector} </div>
        </div>
        <div>
        </div>
      </Grid>
      <CustomTab
        panels={[
          providerSelected.type === 'RECHARGE' && {
            name: "Recargas Celular",
            route: '/admin/proveedores/creds/productos/tae',
            onChange: () => navigate('tae'),
            content: <Outlet />
          },
          providerSelected.type === 'SERVICE' && {
            name: "Pago de Servicios",
            route: '/admin/proveedores/creds/productos/pds',
            onChange: () => navigate('pds'),
            content: <Outlet />
          },
          providerSelected.type === 'SERVICE' && {
            name: "Tarjetas de Regalo",
            route: '/admin/proveedores/creds/productos/pin',
            onChange: () => navigate('pin'),
            content: <Outlet />
          }
        ].filter(Boolean)}
      />
    </div>
  );
}

export default CredsProductTabScreen