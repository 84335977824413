import service from "../service";

export const getCommerceTypeService = (rol, hosterOwner) => new Promise((resolve) => resolve([
  ['Administrador', 'H2H'].includes(rol) && { id: 'H2H', name: "H2H" },
  ['Administrador', 'Distribuidor'].includes(rol) && !hosterOwner && { id: 'DISTRIBUIDOR', name: "Distribuidor" },
  ['Administrador', 'Distribuidor', 'Menudeo'].includes(rol) && { id: 'MENUDEO', name: "Menudeo" },
].filter(item => item)))

export const getCommercesService = (type, state, group) => (
  service('post', `v3/comercio/${type}`, { data: { state, group } })
)

export const createCommerceService = (data) => (
  service('post', `v3/comercio`, { data })
)

export const updateCommerceService = (idCommerce, data) => (
  service('patch', `v3/comercio/${idCommerce}`, { data })
)
export const updateCommerceStateService = (idCommerce, data) => (
  service('patch', `v3/comercio/${idCommerce}/estado`, { data })
)

export const removeCommerceService = (idCommerce) => (
  service('delete', `v3/comercio/${idCommerce}`)
)
