import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form'
import { validateEmptyFields } from '../../helpers/util'
import BackButton from 'components/BackButton'
import InputTextFieldGrid from '../common/InputTextFieldGrid'
import InputSelectFieldGrid from 'components/common/InputSelectFieldGrid'
import { setHebeErrorCodesList } from 'redux/slices/admin/providerSlice';
import { getHebeCodesService } from 'services/admin/providerService';

const ErrorCodesProviderForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({ defaultValues });
  const watch = useWatch({ control });
  const [disabled, setDisabled] = useState(true);
  const { hebeErrorCodesList } = useSelector(store => store.providers)


  useEffect(() => {
    getHebeCodesService().then((result) => {
      dispatch(setHebeErrorCodesList(result));
    })
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center" >
        <Grid container direction="column" m={"40px"} maxWidth={"40%"} justifyContent="center" alignItems="center" spacing={3}>
        <InputSelectFieldGrid
            name="hebeExceptionCode"
            label="Hebe Code"
            required="Debes seleccionar el hebe code"
            control={control}
            data={hebeErrorCodesList}
            formatter={(rows) => {
             return rows.map((row) => {
              return { id: row.name, name: row.name + ' ' + row.code };
             });
            }}
          />
          <InputTextFieldGrid
            name="externalExceptionCode"
            label="External Code"
            required="Debes agregar el external code"
            control={control}
          />
        </Grid>
        <Grid item justifyContent="center" alignItems="center" >
          <Button
            sx={{ m: 2, fontWeight: 'bold', backgroundColor: '#0E73ED' }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          >Guardar</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default ErrorCodesProviderForm