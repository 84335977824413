import { AddOutlined, DeleteOutline, EditOutlined } from "@mui/icons-material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, CustomButton, TableData } from "../../../../components";
import {
  removeOperator,
  setCurrentOperator,
  updateOperator,
} from "../../../../redux/slices/admin/operatorSlice";
import { removeOperatorService, updateOperatorService } from "../../../../services/admin/operatorServices";

import TableChartIcon from '@mui/icons-material/TableChart';

import { confirmAlert, errorAlert } from "../../../../helpers/alerts";
import { validatePermission } from "../../../../helpers/authentication";

const OperatorScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { operators } = useSelector((store) => store.operators);

  const handlerBack = () => navigate(-1);

  const handlerAdd = () => navigate("crear");

  const handlerEdit = (row) => {
    dispatch(setCurrentOperator(row));
    navigate("editar");
  };

  const handlerLegal = (row) => {
    dispatch(setCurrentOperator(row));
    navigate("legales");
  }

  const handlerCheck = async ({ id }, currentValue, col) => {
    const value = !currentValue;
    updateOperatorService(id, { [col]: value })
      .then(() => dispatch(updateOperator({ id, data: { [col]: value } })))
      .catch(error => errorAlert(error))

  };

  const handlerDelete = ({ id, name }) => {
    confirmAlert({
      title: "Estas seguro que deseas eliminar esta operadora?",
      text: `La operadora ${name} será eliminada.`,
      onConfirm: async () => {
        try {
          await removeOperatorService(id);
          dispatch(removeOperator(id));
        }
        catch (error) {
          errorAlert(error)
        }
      }
    })
  };

  return (
    <>
      <TableData
        dataRows={operators}
        headerButtons={[
          validatePermission('productos', 'productos', ['agregar']) &&
          <CustomButton
            icon={<AddOutlined  />}
            key="addOperator"
            name={"Operadora"}
            click={handlerAdd}
          />,
        ]}
        dataTitle={["Logo", "Nombre", "Regiones", "Estado"]}
        formatter={({ name, regions, state, fd }) => [
          { type: "img", value: `/v3/file/${fd}/` },
          name,
          { name: "regions", type: "boolean", value: regions, disabled: !validatePermission('productos', 'productos', ['editar']) },
          { name: "state", type: "boolean", value: state, disabled: !validatePermission('productos', 'productos', ['editar']) },
        ]}
        onChecked={handlerCheck}
        actions={[
          // validatePermission('productos', 'productos', ['legales']) &&
          {
            label: "Legales",
            icon: <TableChartIcon />,
            onClick: (data) => {
              handlerLegal(data);
            },
          },
          validatePermission('productos', 'productos', ['editar']) &&
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              handlerEdit(data);
            },
          },
          validatePermission('productos', 'productos', ['eliminar']) &&
          {
            label: "Eliminar",
            icon: <DeleteOutline />,
            onClick: (data) => {
              handlerDelete(data);
            },
          },
        ]}
      />
    </>
  );
};

export default OperatorScreen;
