import { Button, Grid } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import BackButton from "../BackButton";
import InputImageFieldGrid from "../common/InputImageFieldGrid";
import InputTextFieldGrid from "../common/InputTextFieldGrid";

const DenominationExtraForm = ({
  title,
  goBack,
  defaultValues,
  submit,
}) => {
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          m={"20px"}
          maxWidth={"70%"}
          justifyContent="center"
        >
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            xs={4}
            spacing={3}
          >
            <InputTextFieldGrid
              control={control}
              name="extra_data"
              label="Datos"
              xs={2}
            />
            <InputTextFieldGrid
              control={control}
              name="validity"
              label="Vigencia"
              xs={2}
            />
            <InputTextFieldGrid
              control={control}
              name="name"
              label="Nombre"
              required={"Debes agregar un nombre a la denominación extra"}
              xs={2}
            />
            <InputTextFieldGrid
              control={control}
              name="description"
              multiline={true}
              label="Descripción"
              xs={2}
            />
            <InputTextFieldGrid
              control={control}
              name="gb"
              label="GB"
              xs={2}
            />
          </Grid>
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="center"
            xs={4}
          >
            <InputImageFieldGrid
              control={control}
              name="file"
              label={"Logo"}
              xs={4}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default DenominationExtraForm;
