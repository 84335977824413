import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import {  useForm, useWatch } from "react-hook-form";
import BackButton from "../BackButton";
import { validateEmptyFields } from "../../helpers/util";
import InputTextFieldGrid from "../common/InputTextFieldGrid";
import InputImageFieldGrid from "../common/InputImageFieldGrid";

const SegmentProductForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const [disabled, setDisabled] = useState(true);
  const { control, handleSubmit } = useForm({ defaultValues });
  const watch = useWatch({ control });

  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          container
          direction="row"
          m={"40px"}
          maxWidth={"70%"}
          justifyContent="center"
          spacing={3}
        >
          <InputTextFieldGrid
            xs={6}
            name={"name"}
            control={control}
            required={"Debes agregar el nombre del segmento"}
            label={"Nombre"}
          />
          <InputImageFieldGrid
            xs={8}
            name={"logo"}
            control={control}
            required={"Debes agregar el logo del segmento"}
            label={"Logo"}
            style={{ display: "flex" }}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SegmentProductForm;
