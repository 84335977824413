import { Button, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { validateEmptyFields } from "../../helpers/util";
import BackButton from "../BackButton";
import InputTextFieldGrid from "../common/InputTextFieldGrid";

const LawfulOperatorForm = ({
  title,
  goBack,
  defaultValues,
  submit,
  validate,
}) => {
  const { control, handleSubmit } = useForm({ defaultValues });
  const watch = useWatch({ control });
  const [disabled, setDisabled] = useState(true);
  useEffect(() => {
    setDisabled(validateEmptyFields(watch, { validate }));
    // eslint-disable-next-line
  }, [watch]);

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid container m={"20px"} maxWidth={"70%"} justifyContent="center" >
          <Grid
            container
            item
            direction="column"
            justifyContent="center"
            alignItems="center"
            wrap="nowrap"
            xs={4}
            spacing={3}
          >
            <InputTextFieldGrid
              control={control}
              name="name"
              label="Nombre"
              required={"Debes agregar un nombre a la denominación extra"}
              xs={2}
            />
            <InputTextFieldGrid
              control={control}
              name="description"
              multiline={true}
              label="Descripción"
              xs={2}
            />
          </Grid>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default LawfulOperatorForm;
