import { FormControl, Grid, Input, InputAdornment } from "@mui/material";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, CardBox } from "../../../../components";
import { errorAlert } from "../../../../helpers/alerts";
import { validatePermission } from "../../../../helpers/authentication";
import {
  setCurrentBag,
  setOperators,
  updateOperator,
} from "../../../../redux/slices/client/bagSlice";
import {
  setBagOperatorPurchaseCommissionService,
  updateAllBagOperatorCommissionService,
  updateBagOperatorCommissionService,
  updateBagOperatorStateService,
} from "../../../../services/client/bagService";
import { setLoading } from "../../../../redux/slices/uiSlice";
import { setCurrentCommerce } from "redux/slices/client/commerceSlice";

const BagTAEOperatorScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentCommerce } = useSelector((store) => store.commerces);
  const { operators } = useSelector((store) => store.bags);
  const currentBag = currentCommerce.bags.find(bag => bag.type === 'TAE');
  const { control, setValue } = useForm();
  const [allCommission, setAllCommission] = useState(
    currentBag.earnings === "COMPRA"
      ? currentBag.purchaseCommission || ""
      : ""
  );
  const formatter = (item) => {
    return {
      id: item._id,
      idBagOperator: item.commerce?._id,
      name: item.name,
      image: `/v3/file/${item.fd}/`,
      commission:
        item.commerce?.commission !== undefined ? item.commerce?.commission : 0,
      check: !!item.commerce,
    };
  };
  const handlerCheck = async (item, check) => {
    dispatch(setLoading(true));
    updateBagOperatorStateService(currentBag._id, item.id, { state: check })
      .then((response) => {
        dispatch(
          updateOperator({
            id: item.id,
            data: { commerce: check ? response : null },
          })
        );
      })
      .catch((e) => errorAlert(e))
      .finally(() => dispatch(setLoading(false)));
  };

  const handlerSubmit = async (item, commission) => {
    if (item.commission === commission) return;
    dispatch(setLoading(true));
    updateBagOperatorCommissionService(currentBag._id, item.id, { commission })
      .then(() => {
        dispatch(
          updateOperator({
            id: item.id,
            data: { commerce: { commission: commission } },
          })
        );
      })
      .catch((e) => errorAlert(e))
      .finally(() => dispatch(setLoading(false)));
  };

  const handlerAllOperatorsSetCommission = async () => {
    if (allCommission === "") return;
    dispatch(setLoading(true));
    if (currentBag.earnings === "COMPRA") {
      setBagOperatorPurchaseCommissionService(currentBag._id, {
        commission: allCommission,
      })
        .then(() => {
          currentCommerce.bags.find(bag => bag.type === 'TAE');
          dispatch(setCurrentCommerce({
            ...currentCommerce,
            bags: currentCommerce.bags.map(bag => bag.type === 'TAE' ? {
              ...bag,
              purchaseCommission: allCommission
            } : bag)
          }))
          // dispatch(
          //   setCurrentBag({
          //     ...currentBag,
          //     purchaseCommission: allCommission,
          //   })
          // );
        })
        .catch((e) => errorAlert(e))
        .finally(() => dispatch(setLoading(false)));
    } else {
      updateAllBagOperatorCommissionService(currentBag._id, {
        commission: allCommission,
      })
        .then(() => {
          dispatch(
            setOperators(
              operators.map((operator) => {
                if (operator.commerce) {
                  setValue(operator._id, allCommission);
                  return {
                    ...operator,
                    commerce: {
                      ...operator.commerce,
                      commission: allCommission * 1,
                    },
                  };
                }
                return operator;
              })
            )
          );
          setAllCommission("");
        })
        .catch((e) => errorAlert(e))
        .finally(() => dispatch(setLoading(false)));
    }
  };

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton
            name={"Administrar productos"}
            click={() => navigate(-1)}
          />
        </div>
        <div className="container-title">
          <div className="title">Operadoras</div>
          <div className="subtitle">{currentBag.username}</div>
        </div>
        <div style={{ width: "170px" }}></div>
      </Grid>
      <Grid container direction="row" justifyContent="flex-end">
        <Input
          id="input-commission"
          endAdornment={<InputAdornment position="end">%</InputAdornment>}
          startAdornment={
            <InputAdornment position="start">
              {currentBag.earnings === "COMPRA"
                ? "Comisión General:"
                : "Comisión:"}{" "}
            </InputAdornment>
          }
          aria-describedby="standard-weight-helper-text"
          value={allCommission}
          onChange={(e) => setAllCommission(e.currentTarget.value)}
          type="number"
          inputProps={{
            "aria-label": "weight",
            step: "0.01",
          }}
          style={{ color: "#ED3726" }}
          onBlur={handlerAllOperatorsSetCommission}
          disabled={
            !validatePermission("bolsas", "bolsas", ["agregar", "editar"])
          }
        />
      </Grid>
      <CardBox
        name={"Selecciona las operadoras"}
        values={operators}
        showBorder={false}
        showCheck={validatePermission("bolsas", "bolsas", [
          "agregar",
          "editar",
        ])}
        showInput={true}
        checked={({ check }) => check}
        handleCheck={handlerCheck}
        formatter={formatter}
        element={(item) => (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            {validatePermission("bolsas", "bolsas", ["editar"]) &&
              currentBag.earnings === "VENTA" && (
                <Controller
                  name={item.id}
                  defaultValue={item.commission || 0}
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl
                      sx={{ m: 0, mt: 0, p: 0, width: "165px" }}
                      disabled={true}
                    >
                      <Input
                        endAdornment={
                          <InputAdornment position="end">%</InputAdornment>
                        }
                        startAdornment={
                          <InputAdornment position="start">
                            Comisión:{" "}
                          </InputAdornment>
                        }
                        aria-describedby="standard-weight-helper-text"
                        type="number"
                        inputProps={{
                          "aria-label": "weight",
                          step: "0.01",
                        }}
                        style={{ color: "#ED3726" }}
                        value={value}
                        onChange={onChange}
                        onBlur={() => handlerSubmit(item, value)}
                        error={!!error}
                        helpertext={error && <span> {error.message}</span>}
                        disabled={
                          !item.check ||
                          currentBag.earnings === "compra" ||
                          !validatePermission("bolsas", "bolsas", [
                            "agregar",
                            "editar",
                          ])
                        }
                      />
                    </FormControl>
                  )}
                />
              )}
          </Grid>
        )}
      />
    </>
  );
};

export default BagTAEOperatorScreen;
