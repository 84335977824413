import { Button, Grid } from '@mui/material'
import React from 'react'
import { useForm } from 'react-hook-form'
import BackButton from '../BackButton'
import InputTextFieldGrid from '../common/InputTextFieldGrid'

const DenominationForm = ({ title, goBack, defaultValues, submit, validate }) => {
  const { control, handleSubmit } = useForm({ defaultValues });

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container direction="row" m={"40px"} maxWidth={"70%"} spacing={3} >
          <InputTextFieldGrid
            control={control}
            name="name"
            label="Nombre"
            required={"Debes agregar el nombre de la denominación"}
            xs={3}
          />
          <InputTextFieldGrid
            control={control}
            name="imp"
            label="Importe"
            required={"Debes agregar un importe a la denominación"}
            inputProps={{ type: 'number' }}
            xs={3}
          />
          <InputTextFieldGrid
            control={control}
            name="validity"
            label="Vigencia"
            // required={"Debes agregar una vigencia a la denominación"}
            xs={3}
          />
          <InputTextFieldGrid
            control={control}
            name="min"
            label="Minutos"
            // required={"Debes agregar minutos a la denominación"}
            xs={3}
          />
          <InputTextFieldGrid
            control={control}
            name="sms"
            label="SMS"
            // required={"Debes agregar SMS a la denominación"}
            xs={3}
          />
          <InputTextFieldGrid
            control={control}
            name="gb"
            label="Datos (GB)"
            // required={"Debes agregar datos(GB) a la denominación"}
            xs={3}
          />
          <InputTextFieldGrid
            control={control}
            name="gift"
            label="Saldo de regalo"
            // required={"Debes agregar saldo de regalo a la denominación"}
            xs={3}
          />
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" >
          <Button
            sx={{ m: 2, fontWeight: "bold", backgroundColor: "#0E73ED" }}
            onClick={handleSubmit(submit)}
            // disabled={disabled}
            variant="contained"
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default DenominationForm