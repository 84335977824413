import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom';
import { CustomTab } from '../../../components';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

const BagProductTabScreen = () => {
  const navigate = useNavigate();
  const { currentCommerce } = useSelector((store) => store.commerces);
  const bagTAE = currentCommerce.bags.find(bag => bag.type === 'TAE');
  const bagPDS = currentCommerce.bags.find(bag => bag.type === 'PDS');
  useEffect(() => {
    if(bagTAE) navigate('tae')
    else if(bagPDS) navigate('pds')
  }, [])
  return (
    <div>
      <CustomTab
        title={`Comercio: ${currentCommerce.username}`}
        back={true}
        panels={[
          bagTAE && {
            name: "Recargas Celular",
            route: '/cliente/bolsas/productos/tae',
            onChange: () => navigate('tae'),
            content: <Outlet />
          },
          bagPDS && {
            name: "Pago de Servicios",
            route: '/cliente/bolsas/productos/pds',
            onChange: () => navigate('pds'),
            content: <Outlet />
          },
          bagPDS && {
            name: "Tarjetas de Regalo",
            route: '/cliente/bolsas/productos/pin',
            onChange: () => navigate('pin'),
            content: <Outlet />
          }
        ].filter(Boolean)}
      />
    </div>
  );
}

export default BagProductTabScreen