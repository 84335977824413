import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './CustomButton.css'

const CustomButton = ({ id, name, click, additional_class, icon, loading }) => {
  const [time, setTime] = useState(0)

  useEffect(() => {
    let interval
    if (loading) {
     interval = setInterval(() => {
        setTime(time => time + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      setTime(0)
    }
    return () => clearInterval(interval);
  }, [loading])

  return (
    <Button
      id={id}
      variant="outlined"
      startIcon={icon}
      className={`button ${additional_class}`}
      onClick={click}
      disabled={loading}
    >
      {loading ? `Procesando... ${time} seg` : name}
    </Button>
  )
}

export default CustomButton

