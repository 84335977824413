import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommerceForm } from '../../../components';
import { errorAlert } from '../../../helpers/alerts';
import { hasSameValues } from '../../../helpers/util';
import { updateCommerce } from '../../../redux/slices/client/commerceSlice';
import { updateCommerceService } from '../../../services/client/commerceService';

const EditCommerceScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { mdd } = useSelector(store => store.session)
  const { currentCommerce: current } = useSelector((store) => store.commerces);
  const values = {
    commerce_name: current.username,
    name: current.name_res,
    last_name: current.lastname_p,
    second_last_name: current.lastname_m,
    street_number: current.street,
    cp: current.cp === 0 ? '' : current.cp,
    municipality: current.municipality,
    state_address: current.state_address,
    phone: current.phone === 0 ? '' : current.phone,
    mobile: current.mobile === 0 ? '' : current.mobile,
    earnings_scheme: current.earnings_scheme,
    earnings: current.earnings,
    mdd: current.mdd,
    type_commerce: current.type_commerce,
    social_reason: current.razon_social,
    rfc: current.rfc,
    user_commerce: current.user?.email,
    ip: current.ip,
    conciliation: current.conciliation,
    conciliation_path: current.conciliation_path,
    sessionTime: current.sessionTime,
    name_bag: current.bag.hashtag,
    min_amount: current.bag.min_amount,
    earnings_scheme: current.bag.earnings_scheme,
    earnings: current.bag.earnings,
    name_salepoint: current.salePoint?.name,
    type_salepoint: current.salePoint?.type,
    description_salepoint: current.salePoint?.description,
    limit_amount_day: current.salePoint?.limit_amount_day,
    open_hour: current.salePoint?.open_hour,
    close_hour: current.salePoint?.close_hour,
    days: current.salePoint?.days,
    group: current.group,
    type_bag: current.type_bag,
  }

  const handlerBack = () => {
    navigate("/cliente/comercios");
  };

  const handlerSubmit = async (result) => {
    updateCommerceService(current._id, {
      ...result,
      mdd: mdd || result.mdd,
      bag_id: current.bag?._id,
      salePoint_id: current.salePoint?._id,
      state: true
    }).then(result => {
      dispatch(updateCommerce({ id: current._id, data: result }));
      navigate("/cliente/comercios");
    }).catch(error => errorAlert(error))
  }
  const validate = (data) => !hasSameValues(values, data)
  return (
    <CommerceForm
      title={"Editar Comercio"}
      goBack={handlerBack}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  )
}

export default EditCommerceScreen