import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CommerceForm } from '../../../components';
import { confirmAlert, errorAlert } from '../../../helpers/alerts';
import { addCommerce } from '../../../redux/slices/client/commerceSlice';
import { createCommerceService } from '../../../services/client/commerceService';
import { setLoading } from '../../../redux/slices/uiSlice';
import { Cancel } from '@mui/icons-material';
import DialogModal from 'components/modal/DialogModal';

const CreateCommerceScreen = () => {
  const { mdd } = useSelector(store => store.session)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dialog, setDialog] = useState({ open: false, title: '', body: '' });

  const handlerBack = () => {
    navigate("/cliente/comercios");
  };

  const handlerSubmit = async (data) => {
    dispatch(setLoading(true));
    if (mdd)
      data.mdd = mdd;
    createCommerceService(data).then(({ commerce, password }) => {
      dispatch(addCommerce(commerce));
      navigate("/cliente/comercios");
      if (data.type_commerce === 'H2H') {
        confirmAlert({
          title: "¡El comercio se ha creado exitosamente! ",
          icon: 'success',
          html: `
          <div>
            <h4>Credenciales de acceso</h4>
            <div><b>Usuario:</b> ${data.user_commerce}</div>
            <div><b>Contraseña:</b>  ${password}</div>
          </div>
          `,
          showCancelButton: false,
        })
      }
    }).catch((e) => {
      console.error(e);
      setDialog({
        open: true,
        title: "Error",
        body:
          <div>
            <Cancel color="error" sx={{ width: 60, height: 60 }} />
            <div>{e.message}</div>
          </div>,
        onClose: () => setDialog({ open: false })
      })
    }).finally(() => dispatch(setLoading(false)));
  }

  return (
    <>
      <CommerceForm
        title={"Comercios"}
        goBack={handlerBack}
        submit={handlerSubmit}
        defaultValues={{
          sessionTime: 10,
          days: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
        }}
      />
      <DialogModal {...dialog} />
    </>
  )
}

export default CreateCommerceScreen