import React, { useEffect, useState } from "react";
import { CustomButton, FilterBar, TableData } from "../../components";
import { BugReport, Downloading, DownloadingOutlined, Filter1Outlined, PendingOutlined } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { downloadNetworkReportService, getNetworkReportService } from "../../services/report/reportService";
import { setNetworkReport, setNetworkReportFilters } from "../../redux/slices/report/reportSlice";
import { errorAlert } from "../../helpers/alerts";
import moment from "moment";
import { currencyFormatter, downloadFile } from "../../helpers/util";
import ColumnFilterModal from "../../components/modal/ColumnFilterModal";
import './NetworkReportScreen.css'
import { getLogsTxnService } from "services/panelService";
import { setLogsTxn } from "redux/slices/report/reportSlice";
import { getGroupsService } from "services/client/groupService";
import { setGroups } from "redux/slices/client/groupSlice";
import { validatePermission } from 'helpers/authentication';
import { getAllProviderCredsService } from "services/admin/providerService";
import { setProviderCreds } from "redux/slices/admin/providerSlice";

const NetworkReportScreen = () => {
  const dispatch = useDispatch();
  const { networkReport, networkReportFilters } = useSelector((store) => store.reports);
  const { providerCreds } = useSelector(store => store.providers)
  const { groups } = useSelector(store => store.group);
  const { type } = useSelector(store => store.session);
  const [operators, setOperators] = useState([])
  const [commerces, setCommerces] = useState([])
  const [bags, setBags] = useState([])
  const [salepoints, setSalepoints] = useState([])
  const [credentials, setCredentials] = useState([])
  const [providers, setProviders] = useState([])
  const [loading, setLoading] = useState(false)
  const [showColumnFilterModal, setShowColumnFilterModal] = useState(false)
  const columns = [
    { category: 'Destino', name: "type", label: "Movimiento", format: ({ etagSaldo, typeReg }) => etagSaldo || typeReg },
    { category: 'Destino', name: "operator", label: "Operadora", format: ({ operator }) => operator },
    { category: 'Destino', name: "phone", label: "Telefono/Referencia", format: ({ phoneNumber, typeReg, reference, referenceSaldo, operacionOrigen }) => (typeReg == 'PDS' || operacionOrigen) ? (reference || referenceSaldo || '-') : ['TAE', 'PIN'].includes(typeReg) ? (phoneNumber || '-') : '-' },
    { category: 'Destino', name: "productType", label: "Segmento", format: ({ productType }) => productType },
    { category: 'Destino', name: "import", label: "Importe", format: ({ typeReg, amount, operacionSaldo }) => currencyFormatter.format(['TAE', 'PDS', 'PIN'].includes(typeReg) ? amount : operacionSaldo) },
    { category: 'Destino', name: "reference", label: "Referencia", format: ({ typeReg, ProviderReference, referenceSaldo }) => ['TAE', 'PDS', 'PIN'].includes(typeReg) ? ProviderReference : referenceSaldo },
    { category: 'Destino', name: "region", label: "Región", format: ({ region }) => region },
    { category: 'Saldo', name: "prevBalance", label: "Anterior", format: ({ saldoAnterior }) => saldoAnterior ? currencyFormatter.format(saldoAnterior) : '' },
    { category: 'Saldo', name: "currentBalance", label: "Actual", format: ({ saldoActual }) => saldoActual ? currencyFormatter.format(saldoActual) : '' },
    { category: 'Saldo', name: "operacionSaldo", label: "Debitado", format: ({ operacionSaldo }) => currencyFormatter.format(operacionSaldo) },
    type == 'admin' && { category: 'Saldo', name: "discount_porcent", label: "% Descuento", format: ({ discount_porcent }) => discount_porcent ? `${discount_porcent}%` : '' },
    type == 'admin' && { category: 'Saldo', name: "discount_applied", label: "Descuento", format: ({ discount_applied }) => discount_applied ? currencyFormatter.format(discount_applied) : '' },
    { category: 'Resultado', name: "state", label: "Estado", format: ({ typeReg, ProviderResponseCode }) => ['TAE', 'PDS', 'PIN'].includes(typeReg) ? ProviderResponseCode != "00" ? 'DENEGADA' : 'APROBADA' : '' },
    { category: 'Resultado', name: "message", label: "Mensaje", format: ({ typeReg, ProviderResponseMessage, ProviderResponseCode }) => ['TAE', 'PDS', 'PIN'].includes(typeReg) && ProviderResponseCode != '00' ? ProviderResponseMessage : '' },
    { category: 'Resultado', name: "datetime", label: "Fecha y hora", format: ({ createdAt }) => moment(createdAt).format('DD/MM/YYYY HH:mm:ss') },
    { category: 'Resultado', name: "auth", label: "Autorización", format: ({ AuthCode }) => AuthCode },
    { category: 'Resultado', name: "code", label: "Codigo", format: ({ ProviderResponseCode }) => ProviderResponseCode },
    { category: 'Resultado', name: "time", label: "Tiempo", format: ({ time }) => `${time} ms` },
    { category: 'Entidad', name: "commerceId", label: "ID Comercio", format: ({ commerceIdentification }) => commerceIdentification },
    { category: 'Entidad', name: "commerce", label: "Comercio", format: ({ commerce }) => commerce },
    { category: 'Entidad', name: "salepoint", label: "Punto de Venta", format: ({ salepoint }) => salepoint },
    { category: 'Entidad', name: "bag", label: "Bolsa", format: ({ hosterBagId }) => hosterBagId.hashtag },
    type == 'admin' && { category: 'Entidad', name: "provider", label: "Proveedor", format: ({ serviceName }) => serviceName },
    { category: 'Entidad', name: "user", label: "Usuario", format: ({ user }) => user?.nameUser },
  ].filter(Boolean);
  const [selectedColums, setSetSelectedColums] = useState([
    "operator",
    "phone",
    "productType",
    "import",
    "state",
    "message",
    "reference2",
    "datetime",
    "auth",
    "salepoint",
    "commerce",
  ])
  const { logs } = useSelector(store => store.reports)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    getAllProviderCredsService().then(response => dispatch(setProviderCreds(response))).catch(error => errorAlert(error));
    // dispatch(setNetworkReportFilters(null))
  }, []);

  useEffect(() => {
    if (isModalOpen && logs.length > 0) {
      let logsWin = window.open("about:blank", "logs", "width=640,height=320");
      logs.map(({ uuid, date, message }) => {
        logsWin.document.write(uuid + " " + date + " " + message + "<br/>");
      });
      setIsModalOpen(false);
    }
  }, [isModalOpen, logs]);

  useEffect(() => {
    getGroupsService()
      .then((response) => dispatch(setGroups(response)))
      .catch((e) => errorAlert(e))
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    try {
      setOperators(networkReport
        .map(({ operatorId: id, operator: name }) => ({ id, name }))
        .filter(({ id }, index) => index === networkReport.findIndex(o => id === o.operatorId))
      );
    } catch (_) { }
    try {
      setCommerces(networkReport
        .map(({ commerceId: id, commerce: name }) => ({ id, name }))
        .filter(({ id }, index) => index === networkReport.findIndex(o => id === o.commerceId))
      );
    } catch (_) { }
    try {
      setBags(networkReport
        .map(({ hosterBagId: { id, hashtag: name } }) => ({ id, name }))
        .filter(({ id }, index) => index === networkReport.findIndex(o => id === o.hosterBagId?.id))
      );
    } catch (_) { }
    try {
      setSalepoints(networkReport
        .map(({ salepointId: id, salepoint: name }) => ({ id, name }))
        .filter(({ id }, index) => index === networkReport.findIndex(o => id === o.salepointId))
        .filter(({ id }) => id)
      );
    } catch (_) { }
    try {
      setCredentials(networkReport
        .map(({ credentialId: id, credential: name }) => ({ id, name }))
        .filter(({ id }, index) => index === networkReport.findIndex(o => id === o.credentialId))
        .filter(({ id }) => id)
      );
    } catch (_) { }
    try {
      const providers = networkReport.map(({ credentialId }) => {
        const cred = providerCreds.find(({ id }) => id === credentialId);
        return cred ? { id: cred.provider?.host, name: cred.provider?.host } : { id: null, name: null };
      })
      setProviders(providers.filter(({ id }, index) => index === providers.findIndex(o => id === o.id))
        .filter(({ id }) => id)
      );
    } catch (_) { }
    // eslint-disable-next-line
  }, [networkReport]);

  const handlerSubmit = async (data) => {
    dispatch(setNetworkReportFilters(data))
    setLoading(true)
    getNetworkReportService(data).then((result) => dispatch(setNetworkReport(result)))
      .catch((error) => errorAlert(error))
      .finally(() => setLoading(false));
  }

  const handlerDownload = async () => {
    setLoading(true)
    downloadNetworkReportService(selectedColums, {
      ...networkReportFilters,
      page: undefined,
      limit: undefined,
    })
      .then((data) => downloadFile(data, 'Reporte Detallado.xlsx'))
      .catch((e) => errorAlert(e))
      .finally(() => setLoading(false));
  }

  const handlerSelectColumn = (columns) => {
    setSetSelectedColums(columns)
    setShowColumnFilterModal(false)
  }

  return (
    <>
      <FilterBar
        handlerFilter={handlerSubmit}
        showButton={!!networkReportFilters}
        defaultValues={networkReportFilters ? {
          ...networkReportFilters,
          date: networkReportFilters.date.map((date) => new Date(date)),
        } : {
          type: 1,
          date: [new Date(), new Date()],
        }}
        allValue={null}
        filters={[
          {
            type: "dates",
            name: "date",
            label: "Fecha",
          },
          {
            name: 'type',
            label: "Tipo",
            type: "select",
            all: 'Todos',
            data: [
              { id: 1, name: "Venta" },
              { id: 2, name: "Abonos" },
            ],
          },
          {
            name: 'typeReg',
            label: "Tipo de Venta",
            type: "select",
            all: 'Todos',
            data: [
              { id: 'TAE', name: "Tiempo Aire" },
              { id: 'PDS', name: "Pago de Servicios" },
              { id: 'PIN', name: "Tarjetas de Regalo" },
            ],
          },
          {
            name: "operator",
            label: "Operadora",
            type: "select",
            all: 'Todas',
            data: operators,
          },
          {
            name: "state",
            label: "Estado",
            type: "select",
            all: 'Todos',
            data: [
              { id: 1, name: "Aprobadas" },
              { id: 2, name: "Denegadas" },
            ],
          },
          {
            name: 'group',
            label: "Grupo",
            type: "select",
            all: 'Todos',
            data: groups,
          },
          {
            name: 'commerce',
            label: "Comercio",
            type: "select",
            all: 'Todos',
            data: commerces,
          },
          {
            name: 'bag',
            label: "Bolsa",
            type: "select",
            all: 'Todas',
            data: bags,
          },
          {
            name: 'salepoint',
            label: "Punto de Venta",
            type: "select",
            all: 'Todas',
            data: salepoints,
          },
          type == 'admin' && {
            name: 'provider',
            label: "Proveedor",
            type: "select",
            all: 'Todas',
            data: providers,
          },
          type == 'admin' && {
            name: 'credential',
            label: "Instancia",
            type: "select",
            all: 'Todas',
            data: credentials,
          },
        ]}
      />
      <TableData
        loading={loading}
        // dataRows={networkReport}
        dataRows={networkReport?.map((item) => ({
          ...item,
          style: item.ProviderResponseCode != "00" && ['TAE', 'PDS', 'PIN'].includes(item.typeReg) && { color: 'red' }
        }))}
        headerButtons={[
          <CustomButton
            icon={<Filter1Outlined  />}
            key="filterColumns"
            name={"Columnas"}
            additional_class={"columnFilterButton"}
            click={() => setShowColumnFilterModal(true)}
          />,
          <CustomButton
            icon={<DownloadingOutlined />}
            key="dowloadDetails"
            name={"Descargar"}
            click={handlerDownload}
          />,
        ]}
        dataTitle={columns.filter(({ name }) => selectedColums.includes(name)).map(({ label }) => label)}
        formatter={(data) => columns.filter(({ name }) => selectedColums.includes(name)).map(({ format }) => format(data))}
        actions={[
          validatePermission('proveedores', 'proveedores', ['agregar']) && {
            label: "Logs",
            icon: <BugReport />,
            onClick: async (data) => {
              dispatch(setLogsTxn([]));
              console.log("dsts.report", data);
              const result = await getLogsTxnService(data._id);
              dispatch(setLogsTxn(result));
              setIsModalOpen(true);
            },
          },
        ]}
      />
      <ColumnFilterModal
        open={showColumnFilterModal}
        columns={columns}
        selected={selectedColums}
        onSuccess={handlerSelectColumn}
        onClose={() => setShowColumnFilterModal(false)}
      />
    </>
  );
};

export default NetworkReportScreen;
