import { AddCircleOutline, Key, ErrorOutline, Category, EditOutlined, AddOutlined, KeyOutlined } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomButton, TableData } from 'components';
import { getAllProviderCredsService, getProveedoresService, getProviderCredsService } from 'services/admin/providerService';

import { setCurrentProvider, setProviders, setProviderCreds, setProviderSelected, setJustCreds, setCurrentOperator, setCurrentSegment } from 'redux/slices/admin/providerSlice';
import { errorAlert } from 'helpers/alerts';
import { validatePermission } from 'helpers/authentication';
import { setProviderCredSelected } from 'redux/slices/admin/providerSlice';
import { Checkbox, FormControlLabel } from '@mui/material';

const ProviderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { justCreds, providers, providerCreds } = useSelector(store => store.providers)

  useEffect(() => {
    if (justCreds)
      getAllProviderCredsService().then(response => dispatch(setProviderCreds(response))).catch(error => errorAlert(error));
    else
      getProveedoresService().then(response => dispatch(setProviders(response))).catch(error => errorAlert(error));
    // eslint-disable-next-line
  }, [justCreds]);

  return (
    <TableData
      dataRows={justCreds ? providerCreds : providers}
      headerButtons={[
        <FormControlLabel
          label={'Ver solo credenciales'}
          control={
            <Checkbox
              checked={justCreds}
              onChange={(e) => {
                dispatch(setJustCreds(e.target.checked))
              }}
            />
          }
        />,
        validatePermission('proveedores', 'proveedores', ['agregar']) &&
        <CustomButton
          icon={<AddOutlined  />}
          key="addProvider"
          name={"Proveedor"}
          click={() => navigate("crear")}
        />,
      ]}
      dataTitle={justCreds ? [
        "Id",
        "Proveedor",
        "Nombre",
        "Client Id",
        "Username",
        "Conectada",
        "Conciliación",
      ] : [
        "Id",
        "Nombre",
        "Tipo",
        "Host",
      ]}
      formatter={({
        identification,
        //provider
        container_name, type, host,
        //provider_creds
        conciliacion_service, name_connector, client_id, user_name, active, conciliacion
      }) => (justCreds ? [
        identification,
        conciliacion_service,
        name_connector,
        client_id,
        user_name,
        active ? "SI" : "NO",
        conciliacion ? "ACTIVA" : "INACTIVA"
      ] : [
        identification,
        container_name,
        type,
        host,
      ])}
      actions={justCreds ?
        [
          validatePermission('proveedores', 'productos', ['ver']) &&
          {
            label: "Productos",
            icon: <Category />,
            onClick: (data) => {
              dispatch(setProviderCredSelected(data));
              dispatch(setCurrentOperator(null));
              dispatch(setCurrentSegment(null));
              navigate(data.provider?.type === 'RECHARGE' ? "creds/productos/tae" : "creds/productos/pds");
            },
          },
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              dispatch(setProviderCredSelected(data));
              navigate("creds/edit");
            },
          },
        ] : [
          validatePermission('proveedores', 'proveedores', ['editar']) &&
          {
            label: "Editar",
            icon: <EditOutlined />,
            onClick: (data) => {
              dispatch(setCurrentProvider(data));
              navigate("editar")
            },
          },
          validatePermission('proveedores', 'productos', ['editar']) &&
          {
            label: "Credenciales",
            icon: <KeyOutlined />,
            onClick: (data) => {
              getProviderCredsService(data.host).then(response => {
                dispatch(setProviderCreds(response));
                dispatch(setProviderSelected(data));
                navigate("creds");
              }).catch(error => errorAlert(error));
            },
          },
        ]}
    />
  )
}

export default ProviderScreen