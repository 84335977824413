import { ErrorOutline } from '@mui/icons-material';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TableData } from 'components';
import { getProveedoresService } from 'services/admin/providerService';

import { setCurrentProvider, setProviders } from 'redux/slices/admin/providerSlice';
import { errorAlert } from 'helpers/alerts';
import { validatePermission } from 'helpers/authentication';

const HomologationScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { providers } = useSelector(store => store.providers)

  useEffect(() => {
    getProveedoresService()
    .then(response => dispatch(setProviders(response.filter(item => ![
      'sandbox',
      'smartgateiso',
      'certificacion'
    ].some(substring => item.host.includes(substring))))))
    .catch(error => errorAlert(error));
    // eslint-disable-next-line
  }, []);

  return (
    <TableData
      dataRows={providers}
      dataTitle={[
        "Id",
        "Nombre",
        "Tipo",
        "Host",
      ]}
      formatter={({
        identification,
        container_name, type, host,
      }) => ([
        identification,
        container_name,
        type,
        host,
      ])}
      actions={[
        validatePermission('proveedores', 'productos', ['editar']) &&
        {
          label: "Error Codes",
          icon: <ErrorOutline />,
          onClick: (data) => {
            dispatch(setCurrentProvider(data));
            navigate("errorcodes/homologados");
          },
        },
      ]}
    />
  )
}

export default HomologationScreen