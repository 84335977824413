import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BackButton, CardBox } from "../../../../components";
import { errorAlert } from "../../../../helpers/alerts";
import { Edit, EditOffOutlined, EditOutlined } from "@mui/icons-material";
import {
  getBagOperatorService,
  getBagRegionService,
  getBagSegmentService,
  getBagDenominationsService,
} from "../../../../services/client/bagService";
import {
  setOperators,
  setCurrentOperator,
  setRegions,
  setCurrentRegion,
  setSegments,
  setCurrentSegment,
  setDenominations,
} from "../../../../redux/slices/client/bagSlice";
import { setTitle } from "../../../../redux/slices/uiSlice";
import { validatePermission } from "helpers/authentication";

const BagTAEScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hoster } = useSelector(store => store.session);
  const { currentCommerce } = useSelector((store) => store.commerces);
  const currentBag = currentCommerce.bags.find(bag => bag.type === 'TAE');
  const {
    operators,
    regions,
    segments,
    denominations,
    currentOperator,
    currentRegion,
    currentSegment,
  } = useSelector((store) => store.bags);

  useEffect(() => {
    dispatch(setTitle('Productos de la bolsa'));
    getBagOperatorService(currentBag._id)
      .then((response) => dispatch(setOperators(response)))
      .catch((error) => errorAlert(error));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (currentOperator) {
          if (currentOperator.regions) {
            const result = await getBagRegionService(
              currentBag._id,
              currentOperator._id
            );
            dispatch(setRegions(result));
          } else {
            const result = await getBagSegmentService(
              currentBag._id,
              currentOperator._id
            );
            dispatch(setSegments(result));
          }
        }
      } catch (error) {
        errorAlert(error);
      }
    })();
    // eslint-disable-next-line
  }, [currentOperator]);

  useEffect(() => {
    (async () => {
      if (currentRegion) {
        try {
          const result = await getBagSegmentService(
            currentBag._id,
            currentOperator._id,
            currentRegion._id
          );
          dispatch(setSegments(result));
        } catch (error) {
          errorAlert(error);
        }
      }
    })();
    // eslint-disable-next-line
  }, [currentRegion]);

  useEffect(() => {
    (async () => {
      try {
        if (currentSegment) {
          const result = await getBagDenominationsService(
            currentBag._id,
            currentOperator._id,
            currentRegion?._id,
            currentSegment._id
          );
          dispatch(setDenominations(result));
        }
      } catch (error) {
        errorAlert(error);
      }
    })();
    // eslint-disable-next-line
  }, [currentSegment]);

  const handlerClickOperator = (bagOperator) => {
    if (bagOperator._id !== currentOperator?._id) {
      batch(() => {
        dispatch(setCurrentOperator(bagOperator));
        dispatch(setCurrentRegion());
        dispatch(setCurrentSegment());
      });
    }
  };
  const handlerClickRegion = (bagRegion) => {
    if (bagRegion._id !== currentRegion?._id) {
      batch(() => {
        dispatch(setCurrentRegion(bagRegion));
        dispatch(setCurrentSegment());
      });
    }
  };
  const handlerClickSegment = (bagSegment) => {
    if (bagSegment._id !== currentSegment?.id)
      dispatch(setCurrentSegment(bagSegment));
  };
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>
          <BackButton click={() => navigate('/cliente/comercios')} />
        </div>
        <div className="container-title">
          <div className="commerce">{currentCommerce.username} </div>
          <div className="bag">{currentBag.username} </div>
        </div>
        <div style={{ width: "170px" }}>
          {
            (currentBag.earnings === 'COMPRA' ? `Comisión General: ${currentBag.purchaseCommission || '0'}%` : '')
          }
        </div>
      </Grid>
      <div>
        <CardBox
          name={"Operadoras"}
          values={operators.filter((item) => item.commerce)}
          handleSelect={handlerClickOperator}
          isSelect={currentOperator?._id}
          buttons={[
            //  validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
            hoster != currentCommerce._id && {
              icon: <EditOutlined  />,
              key: "operators",
              id: "operators",
              name: "Editar",
              click: () => navigate("operadoras"),
            },
          ]}
          formatter={(item) => {
            const { commission } = item.commerce;
            return {
              ...item,
              id: item._id,
              image: `/v3/file/${item.fd}/`,
              commission,
            };
          }}
          element={({ commission }) => (
            currentBag.earnings === 'VENTA' &&
            <div className="commission">Comisión: {commission}%</div>
          )}
        />
      </div>
      <div>
        {currentOperator?.regions && (
          <CardBox
            name={"Regiones"}
            values={regions.filter((item) => item.commerce)}
            handleSelect={handlerClickRegion}
            isSelect={currentRegion?._id}
            buttons={[
              // validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
              hoster != currentCommerce._id && {
                icon: <EditOutlined  />,
                key: "regions",
                name: "Editar",
                click: () => navigate("regiones"),
              },
            ]}
            formatter={(item) => {
              const { commission } = item.commerce;
              return {
                ...item,
                id: item._id,
                name: item.nombre,
                commission,
              };
            }}
            element={({ commission }) => (
              currentBag.earnings === 'VENTA' &&
              <div className="commission">Comisión: {commission}%</div>
            )}
          />
        )}
      </div>
      <div>
        {((currentOperator && !currentOperator.regions) || currentRegion) && (
          <CardBox
            name={"Segmentos"}
            values={segments.filter((item) => item.commerce)}
            handleSelect={handlerClickSegment}
            isSelect={currentSegment?._id}
            buttons={[
              // validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
              hoster != currentCommerce._id && {
                icon: <EditOutlined  />,
                key: "segments",
                name: "Editar",
                click: () => navigate("segmentos"),
              },
            ]}
            formatter={(item) => {
              const { commission } = item.commerce;
              return {
                ...item,
                id: item._id,
                image: `/v3/file/${item.fd}/`,
                commission,
              };
            }}
            element={({ commission }) => (
              currentBag.earnings === 'VENTA' &&
              <div className="commission">Comisión: {commission}%</div>
            )}
          />
        )}
      </div>
      <div>
        {currentSegment && !denominations.error && (
          <CardBox
            name={"Denominaciones"}
            values={denominations.filter((item) => item.commerce).sort((a, b) => a.denomination - b.denomination)}
            buttons={[
              //validatePermission('proveedores', 'productos', ['agregar', 'editar', 'eliminar']) &&
              hoster != currentCommerce._id && {
                icon: <EditOutlined  />,
                key: "denominations",
                name: "Editar",
                click: () => navigate("denominaciones"),
              },
            ]}
            formatter={(item) => {
              const { commission } = item.commerce;
              return {
                ...item,
                id: item._id,
                name: item.denomination,
                commission,
                hostproduct: item.hostproduct,
              };
            }}
            element={({ commission, hostproduct }) => (
              <>
                {
                  validatePermission('bolsas', 'bolsas', ['editar']) &&
                  <div style={{
                    position: '',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    margin: '0 10px',
                  }}>
                    <div style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: hostproduct ? '#007328' : '#ED3726',
                    }} />
                    {
                      hostproduct &&
                      <div style={{
                      }} >{hostproduct?.ladas?.length || ''} </div>
                    }
                  </div>

                }
                {
                  currentBag.earnings === 'VENTA' &&
                  <div style={{
                    textAlign: 'center',
                  }}>Comisión: {commission}%</div>
                }
              </>
            )}
          />
        )}
      </div>
    </>
  );
};

export default BagTAEScreen;
