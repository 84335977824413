import moment from "moment";
import React, { useEffect, useState } from "react";
import { BackButton, TableData } from "../../components";
import { errorAlert } from "../../helpers/alerts";
import { getPaymentsService } from "../../services/panelService";
import { useNavigate } from "react-router-dom";

const PanelPaymentsScreen = ({ slim }) => {
  const navigate = useNavigate();
  const [list, setList] = useState([])
  const [loading, setLoading] = useState(false)
  const shortList = new Array(15).fill({});
  useEffect(() => {
    setLoading(true)
    getPaymentsService(0, 300)
      .then((response) => setList(response))
      .catch((error) => errorAlert(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {!slim && <BackButton name={'Regresar'} click={() => navigate(-1)} />}
      <TableData
        title={"Abonos"}
        onTitleClick={() => slim && navigate('../abonos')}
        maxHeight="40vh"
        loading={loading}
        autonumber={false}
        pagination={false}
        rowsPerPageOpt={50}
        headerStyle={{
          fontSize: slim ? 10 : 12,
        }}
        dataRows={(slim ? shortList.map((item, i) => list[i] || {}) : list).map((item) => ({
          ...item,
          style: {
            fontSize: slim ? 11 : 12,
          }
        }))}
        dataTitle={[
          "Fecha Hora",
          "Comercio",
          "Tipo",
          "Importe",
          // "Comercio",
          "Usuario",
        ]}
        formatter={({
          createdAt,
          hosterBagId,
          etagSaldo,
          operacionSaldo,
          hashtag,
          user
        }) => ([
          createdAt ? moment(createdAt).format(slim ? 'HH:mm:ss' : 'DD/MM/YY HH:mm:ss') : '-',
          slim ? (hosterBagId?.hashtag ? { type: 'text', value: hosterBagId?.hashtag.replace('Bolsa ', '') } : '-') : hosterBagId?.hashtag?.replace('Bolsa ', ''),
          slim ? etagSaldo ? etagSaldo.replace('ABONO DE', '').replace('CARGO DE', '') : '-' : etagSaldo,
          operacionSaldo ? `$${Number(operacionSaldo || 0).toFixed(2)}` : '-',
          // hashtag,
          user?.nameUser || '-',
        ])}
      />
    </>
  );
};

export default PanelPaymentsScreen;
