import { Checkbox, Grid } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React from 'react'
import './CardItem.css'

const CardItem = ({
  name,
  src,
  click,
  active,
  checked,
  showBorder = true,
  showCheck = true,
  onCheck,
  children,
}) => {
  return (
    <Grid item md={3} >
      <div id={name} className={`item-card ${showBorder && active ? 'active' : ''}`} onClick={click}>
        <span className="card-item-select">
          {
            showCheck && (
              <Checkbox
                id={'check' + name}
                icon={<RadioButtonUncheckedIcon style={{ color: "#ED3726" }} />}
                checkedIcon={<CheckCircleOutlineIcon style={{ color: "#ED3726" }} />}
                sx={{ p: '0px' }}
                checked={checked}
                onChange={(e) => onCheck(e.target.checked)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )
          }
        </span>
        <Grid container direction="column" justifyContent="center" alignItems="center" >
          {src && <img alt='' className='img-card-item' src={src} />}
          <span className={`card-item-name ${src ? '' : 'label'}`}>{name}</span>
        </Grid>
        {children}
      </div>
    </Grid>
  )
}

export default CardItem