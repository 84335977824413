import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setLoading } from 'redux/slices/uiSlice';
import { errorAlert } from 'helpers/alerts';
import { hasSameValues } from 'helpers/util';
import CredProductForm from 'components/admin/CredProductForm';
import { updateCredPaymentServiceProductService } from 'services/admin/providerService';
import { setPaymentServiceProducts } from 'redux/slices/admin/providerSlice';

const EditCredPDSProductScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    providerCredSelected: cred,
    currentPaymentServiceProduct: product,
  } = useSelector((store) => store.providers);
  const current = product.service;
  const values = {
    sku: current?.sku,
    inline: current?.inline || false,
    partialPayments: current?.partialPayments || false,
    balanceInquiry: current?.balanceInquiry || false,
    commissionProvider: current?.commissionProvider || 0,
    // commissionChannel: current?.commissionChannel || 0,
    commissionUserMax: current?.commissionUserMax || 0,
  }

  const handlerSubmit = async (data) => {
    dispatch(setLoading(true))
    updateCredPaymentServiceProductService(cred.id, product.idSegment.id, product.id, data)
      .then(response => {
        dispatch(setPaymentServiceProducts(response));
        navigate(-1);
      }).catch(error => errorAlert(error))
      .finally(() => dispatch(setLoading(false)))
  }

  const validate = (data) => !hasSameValues(values, data)

  return (
    <CredProductForm
      title="Editar Servicio"
      subtitle={product?.name}
      type={"pds"}
      goBack={() => navigate(-1)}
      defaultValues={values}
      submit={handlerSubmit}
      validate={validate}
    />
  );
}

export default EditCredPDSProductScreen