import { FormControl, FormControlLabel, FormHelperText, Grid, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { Controller } from "react-hook-form";

const InputRadioFieldGrid = ({ xs, name, control, required, label, data, style, disabled = false }) => {
  return (
    <Grid
      item
      xs={xs}
      alignContent="center"
      justifyContent={"center"}
      style={{ ...style, width: "100%" }}
    >
      <Controller
        name={name}
        control={control}
        defaultValue=""
        rules={!disabled && { required }}
        render={({
          field: { value, onChange },
          fieldState: { error }
        }) => (
          <FormControl style={{ width: "100%", display: 'flex', alignItems: 'center' }}>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue=""
              name="radio-buttons-group"
              value={value}
              onChange={onChange}
              error={!error ? error : undefined}
            >
              {
                data.map((item, i) => (
                  <FormControlLabel
                    key={"radio" + i}
                    labelPlacement="top"
                    value={item.id}
                    control={<Radio id={item.id} disabled={disabled || item.disabled} />} label={item.name} />)
                )
              }
            </RadioGroup>
            {error && (
              <FormHelperText sx={{ color: "#d32f2f" }}>
                {required}
              </FormHelperText>
            )}
          </FormControl>
        )}
      />
    </Grid>
  );
};

export default InputRadioFieldGrid;
